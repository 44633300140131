<template>
  <div>
    <form
      v-if="displayQuestionsForm"
      :id="QUESTIONS_FORM_ID"
      :key="props.activeStep?.key"
      @submit.prevent="completeStep"
    >
      <div
        v-for="(question, index) in activeQuestions"
        :key="question.key"
        :class="index === 0 ? '' : 'mt-32'"
      >
        <h3
          :class="[
            'mb-12',
            variant !== 'embedded' ? 'heading-3' : 'body-1-bold',
          ]"
        >
          {{ question.title }}
        </h3>
        <!-- TODO remove once task is done  -->
        <!-- https://backmarket.atlassian.net/browse/BROW-1272 -->

        <component
          :is="question.info.tag"
          v-if="question.showInfo"
          :class="[
            'mt-12',
            'mb-16',
            {
              'list-disc pl-16':
                question.info.tag === 'ul' && variant === 'default',
            },
            {
              'body-2 list-none':
                question.info.tag === 'ul' && variant === 'embedded',
            },
          ]"
        >
          <component
            :is="question.info.subTag"
            v-for="text in question.info.text"
            :key="text"
            :class="{ 'flex gap-12': variant === 'embedded' }"
          >
            <IconCheckSmall v-if="variant === 'embedded'" class="shrink-0" />
            {{ text }}
          </component>
        </component>

        <RevInfoBlock
          v-if="question.infoBlock && variant === 'default'"
          class="my-8"
          :icon="getInfoBlockIcon(question.infoBlock.icon)"
          :title="question.infoBlock.title"
          variant="info"
        >
          <p
            v-for="text in question.infoBlock.paragraphs"
            :key="text"
            class="body-2 mt-8"
          >
            {{ text }}
          </p>
        </RevInfoBlock>

        <RevInputSelect
          v-if="question.isSelect"
          :id="question.key"
          v-model="form[question.key]"
          class="mt-8"
          :data-qa="question.key"
          :label="question.placeholder || ''"
          :options="question.options"
          @update:model-value="question.onChange(question.key)"
        />

        <QuestionsFormRadioInput
          v-if="question.isRadio"
          v-model:radioModel="form[question.key]"
          :question
          :variant="variant === 'embedded' ? 'picker' : 'radio'"
        />

        <div
          v-if="question.infoBlock && variant === 'embedded'"
          class="text-action-default-low body-2 my-8 flex flex-col gap-8"
        >
          <p v-for="text in question.infoBlock.paragraphs" :key="text">
            {{ text }}
          </p>
        </div>
      </div>

      <div
        v-if="isStepComplete && hasSubmitButton"
        class="mt-32 hidden w-full flex-row-reverse lg:flex"
      >
        <RevButton
          :key="`${props.activeStep?.key}-desktop`"
          data-qa="submit-section-desktop"
          :disabled="props.isLoadingOffer"
          type="submit"
          variant="primary"
        >
          {{ buttonLabel }}
        </RevButton>
      </div>
    </form>

    <RevStickyBar
      v-if="hasSubmitButton"
      class="lg:hidden"
      :visible="isStepComplete"
    >
      <RevButton
        :key="`${props.activeStep?.key}-mobile`"
        data-qa="submit-section-mobile"
        :disabled="props.isLoadingOffer"
        :form="QUESTIONS_FORM_ID"
        full-width="always"
        type="submit"
        variant="primary"
      >
        {{ buttonLabel }}
      </RevButton>
    </RevStickyBar>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { LocationQuery } from 'vue-router'

import type {
  FunnelStep,
  InfoBlockIcon,
  Option,
  Question,
  Step,
} from '@backmarket/http-api/src/api-specs-buyback/customer/getQuestionsV3'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevInputSelect } from '@ds/components/InputSelect'
import { RevStickyBar } from '@ds/components/StickyBar'
import { IconBattery } from '@ds/icons/IconBattery'
import { IconCheckSmall } from '@ds/icons/IconCheckSmall'
import { IconInfo } from '@ds/icons/IconInfo'
import { IconQuality } from '@ds/icons/IconQuality'
import { IconSearch } from '@ds/icons/IconSearch'

import translations from './QuestionsForm.translations'
import QuestionsFormRadioInput from './QuestionsFormRadioInput.vue'
import { QUESTIONS_FORM_ID } from './constants'

const i18n = useI18n()

const INFO_BLOCK_ICONS = {
  search: IconSearch,
  battery: IconBattery,
  shield: IconQuality,
  info: IconInfo,
}

const QUESTION_TYPES = {
  SELECT: 'select',
  RADIO: 'radioline',
  CHECKBOX: 'checkbox',
}

const DEFAULTS_LOOKUP = {
  [QUESTION_TYPES.RADIO]: false,
  [QUESTION_TYPES.CHECKBOX]: [],
  [QUESTION_TYPES.SELECT]: '',
}

interface Info {
  text: Array<string> | null
  tag: string
  subTag: string
}

interface RenderOption extends Omit<Option, 'icon'> {
  inputId: string
  icon: string | undefined
}

export interface RenderQuestion extends Omit<Question, 'options'> {
  showInfo: boolean
  info: Info
  options: Array<RenderOption>
  isSelect: boolean
  isRadio: boolean
  isCheckbox: boolean
  onChange: (key: string) => void
}

export interface QuestionsFormProps {
  activeStep: Step | null
  funnel: Array<FunnelStep>
  hasSubmitButton?: boolean
  isLoadingOffer: boolean
  variant?: 'embedded' | 'default'
  autoNext?: boolean
}

export interface NextQuestionPayload {
  formPayload: LocationQuery
  reset?: Array<string>
  trackingKey?: string
}

export interface NextStepPayload {
  formPayload: LocationQuery
  shouldScrollToTop: boolean
  trackingKey?: string
  currentStep?: string
}

export interface SubmitAnswersPayload {
  formPayload: LocationQuery
  currentStep?: string
  trackingKey?: string
}

export interface ShowButtonPayload {
  label: string
  visible: boolean
  scrollBottom: boolean
}

const props = withDefaults(defineProps<QuestionsFormProps>(), {
  activeStep: undefined,
  funnel: () => [],
  hasSubmitButton: true,
  isLoadingOffer: false,
  variant: 'default',
  autoNext: false,
})

const emit = defineEmits<{
  (
    event: 'show-button',
    { label, visible, scrollBottom }: ShowButtonPayload,
  ): void
  (
    event: 'next-question',
    { formPayload, reset, trackingKey }: NextQuestionPayload,
  ): void
  (
    event: 'next-step',
    {
      shouldScrollToTop,
      formPayload,
      currentStep,
      trackingKey,
    }: NextStepPayload,
  ): void
  (
    event: 'submit-answers',
    {
      formPayload,
      currentStep,
      trackingKey,
    }: {
      formPayload: LocationQuery
      currentStep: string
      trackingKey?: string
    },
  ): void
}>()

const form = ref<Record<string, string>>({})
const activeQuestions = ref<Array<RenderQuestion>>([])

const isStepComplete = computed(() => {
  return props.activeStep?.completed || false
})

const isLastQuestion = computed(() => {
  return activeQuestions.value.some(
    (question) => question.lastQuestion === true,
  )
})

const buttonLabel = computed(() => {
  return isLastQuestion.value
    ? i18n(translations.seeOffer)
    : i18n(translations.continue)
})

const displayQuestionsForm = computed(() => {
  return !isEmpty(props.activeStep) && !isEmpty(props.funnel)
})

const getInfoBlockIcon = (id: InfoBlockIcon) => {
  return INFO_BLOCK_ICONS[id] || INFO_BLOCK_ICONS.info
}

const generateFormModel = (
  questions: Array<RenderQuestion>,
): Record<string, string> => {
  return questions.reduce((acc, question) => {
    const { key, type, options } = question

    const defaultValue = DEFAULTS_LOOKUP[type]

    const preSelected = options.find((option) => option.selected)
    const value = preSelected ? preSelected.value : defaultValue

    return {
      ...acc,
      [key]: value,
    }
  }, {})
}

const generateResetPayload = (key: string) => {
  const atIndex = activeQuestions.value.findIndex(
    (question) => question.key === key,
  )

  return Object.keys(form.value)
    .slice(atIndex + 1)
    .reduce((acc: Array<string>, keyToReset: string) => {
      return [...acc, keyToReset]
    }, [])
}

const handleChange = (key: string): void => {
  const resetUrlPayload = generateResetPayload(key)
  // To ensure smooth UX behaviour remove the outated questions as the user interact
  // before updating the url with the current state
  if (!isEmpty(resetUrlPayload)) {
    activeQuestions.value = activeQuestions.value.filter((question) => {
      return !resetUrlPayload.includes(question.key)
    })
  }

  const payload = {
    ...form.value,
    nextStep: 'false',
  }

  emit('next-question', {
    formPayload: payload,
    reset: resetUrlPayload,
    trackingKey: key,
  })
}

const getActiveQuestions = (
  funnel: Array<FunnelStep>,
): Array<RenderQuestion> => {
  const { questions = [] } = funnel.find(({ step }) => step?.active) || {}

  return questions.map((question: Question): RenderQuestion => {
    const infoList = question.subTitleList
    const hasOptions = !isEmpty(question.options)

    return {
      ...question,
      showInfo: !isEmpty(infoList),
      info: {
        text: infoList,
        tag: infoList && infoList.length > 1 ? 'ul' : 'p',
        subTag: infoList && infoList.length > 1 ? 'li' : 'span',
      },
      options: [
        ...(hasOptions
          ? question.options.map((option) => {
              return {
                ...option,
                inputId: `${option.label}-${option.value}`,
                icon: option?.icon?.light,
              }
            })
          : []),
      ],
      infoBlock: question.infoBlock,
      isSelect: question.type === QUESTION_TYPES.SELECT,
      isRadio: question.type === QUESTION_TYPES.RADIO,
      isCheckbox: question.type === QUESTION_TYPES.CHECKBOX,
      onChange: () => handleChange(question.key),
    }
  })
}

const completeStep = (): void => {
  const { key: trackingKey } =
    activeQuestions.value[activeQuestions.value.length - 1]
  if (isLastQuestion.value) {
    emit('submit-answers', {
      formPayload: form.value,
      currentStep: props.activeStep?.key || '',
      trackingKey,
    })

    return
  }

  const payload = {
    ...form.value,
    nextStep: 'true',
  }

  emit('next-step', {
    shouldScrollToTop: true,
    formPayload: payload,
    currentStep: props.activeStep?.key || '',
    trackingKey,
  })
}

watch(
  () => props.funnel,
  (newValue) => {
    activeQuestions.value = [...getActiveQuestions(newValue)]
    form.value = { ...generateFormModel(activeQuestions.value) }
    emit('show-button', {
      label: buttonLabel.value,
      visible: isStepComplete.value,
      scrollBottom: activeQuestions.value.length !== 1,
    })
  },
  { immediate: true },
)

watch(
  [isLastQuestion, isStepComplete],
  ([newIsLastQuestion, newIsStepComplete]) => {
    if (newIsLastQuestion && newIsStepComplete && props.autoNext) {
      completeStep()
    }
  },
  { immediate: true },
)
</script>

<style>
.slide-enter-active,
.slide-leave-enter {
  transform: translateX(0%);
  opacity: 1;
  transition: all 200ms linear;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
